/* eslint-disable radix */
/* eslint-disable prefer-destructuring */
import _ from 'lodash';
import moment from 'moment';

import { store } from 'app/store';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { formatDate, getCurrentTimezone } from 'global-services/OhsDataParse';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { displayTime } from 'global-services/utils/OhsUtility';

import { OhsCusval, OhsCusvalDefinition, OhsCusvalSaveType, OhsCusvalType } from './OhsCusvalModel';
import OhsCusvalValue from './OhsCusvalValue';
// import { OhsCusValCategory } from '../../../user/configurations/OhsModuleCategory';

export const fetchCusvalDefinition = async (_id: string): Promise<OhsCusval[]> => {
  const res: { cusvals: OhsCusval[] } | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.DefinitionFetch,
    { _id }
  );
  return res?.cusvals || [];
};

export const fetchOrgCusvalDefinition = async (
  orgId: string,
  _id: string
): Promise<OhsCusval[]> => {
  const res: { cusvals: OhsCusval[] } | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.DefinitionFetch,
    {
      orgTier: {
        type: 'core.tier.T3',
        _id: orgId,
      },
      _id,
    }
  );
  return res?.cusvals || [];
};

export const fetchOrgBulkCusvalDefinition = async (
  orgId: string,
  _ids: string[]
): Promise<OhsCusvalDefinition[]> => {
  const res = (await OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.DefinitionBulkFetch,
    {
      orgTier: {
        type: 'core.tier.T3',
        _id: orgId,
      },
      _ids,
    }
  )) as OhsCusvalDefinition[];
  return res;
};

export const fetchBulkCusvalDefinition = async (_ids: string[]): Promise<OhsCusval[]> => {
  const res: { cusvals: OhsCusval[] } | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.DefinitionBulkFetch,
    { _ids }
  );
  return res?.cusvals || [];
};

export const fetchCusvalValue = async (_id: string): Promise<OhsCusval[]> => {
  if (_id) {
    const res: any | null = await OhsApiRequest(
      RequestType.Module,
      ModuleType.Cusval,
      OhsApiRequestName.ValuesFetch,
      { _id }
    );
    const cusvals = _.get(res, 'cusvals', []);
    return cusvals;
  }
  return [];
};

export const createCusvalValue = async (
  definitionId: string,
  cusvals: OhsCusval[]
): Promise<OhsCusvalValue | null> => {
  const formattedCusvals = (cusvals || []).map((item: any) => {
    if (item.type === 'date') {
      // convert date to acceptable format
      const formattedDate = formatDate(item.value);
      return { ...item, value: formattedDate };
    }
    // Fix checkbox that have string value
    if (item.type === OhsCusvalType.Checkbox) {
      if (Array.isArray(item.value)) {
        return item;
      }

      if (item.value && typeof item.value === 'string') {
        return {
          ...item,
          value: [item.value],
        };
      }
    }
    return item;
  });
  // empty values are not accepted in create cusval, necessary for invalid cusvals
  const validCusvals = formattedCusvals?.filter(
    (item) => item.value !== null && item.value !== '' && !item?.invalid
  );
  if (!definitionId || (validCusvals || []).length < 1) return null;

  const { user } = store.getState().user;
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.ValuesCreate,
    {
      orgTier: {
        type: user?.org?.type,
        _id: user?.org?._id,
      },
      definition: {
        _id: definitionId,
        type: ModuleType.CusvalDefinition,
      },
      tzDateCreated: getCurrentTimezone(),
      cusvals: validCusvals,
    }
  );
  return {
    _id: res?._id,
    type: ModuleType.CusvalValues,
  };
};

export const setCusvalValue = (
  cusvalObj: any,
  cusvalsConfig: OhsCusval[],
  customPropValues?: any[]
) => {
  const cusvals = new Array<OhsCusval>();
  const keyList = Object.keys(cusvalObj);
  for (let index = 0; index < keyList.length; index += 1) {
    const cusvalId = keyList[index];
    const cusval = _.find(cusvalsConfig, { _id: cusvalId });

    const value = cusvalObj[cusvalId];

    // Handle all possible default values for form fields
    if (cusval && value && value !== undefined && value !== null && value !== '') {
      if (cusval.type === OhsCusvalType.Time) {
        if (value === '') {
          cusval.value = null;
        } else {
          cusval.value = value;
        }
      } else if (cusval.type === OhsCusvalType.Radio) {
        if (value) {
          cusval.value = [value];
        } else {
          cusval.value = value;
        }
      } else {
        cusval.value = value;
      }

      cusvals.push(cusval);
    } else if (value) {
      // filter invalid cusvals only
      const invalidItem = _.find(customPropValues, { _id: cusvalId });

      if (invalidItem) {
        cusvals.push(invalidItem);
      }
    }
  }
  return cusvals;
};

export const parseCusvalResults = (payload: any, cusvalsConfig: OhsCusval[]) => {
  if (payload.cusvals) {
    payload.cusvals = setCusvalValue(payload.cusvals, cusvalsConfig);
  }
  if (payload.categoryCusvals) {
    payload.categoryCusvals = setCusvalValue(payload.categoryCusvals, cusvalsConfig);
  }
  if (payload.subcategoryCusvals) {
    payload.subcategoryCusvals = setCusvalValue(payload.subcategoryCusvals, cusvalsConfig);
  }
  return payload;
};

// TODO - finalize cusval format on all models: object instead of array
// export const returnAllCusvals = (moduleClassification: OhsCusValCategory[]) => {
//   let allCusvals: OhsCusval[] = [];
//   moduleClassification.forEach((item) => {
//     const subcategoryCus: OhsCusval[] = [];
//     item?.subcategory.map((sub) => subcategoryCus.push(...sub.cusvals));
//     allCusvals = [...allCusvals, ...(item?.cusvals || []), ...subcategoryCus];
//   });
//   return allCusvals;
// };

const getFormInitialObject = (cusvalList: Array<OhsCusval>) => {
  const initObject: any = {};
  cusvalList.forEach((item) => {
    const value = item?.value;
    let newValue = null;
    if (value) {
      if (item.type === OhsCusvalType.Date && value.indexOf('T') > 0) {
        newValue = value.split('T')[0];
      } else if (item.type === OhsCusvalType.Radio && value) {
        newValue = value[0];
      } else {
        newValue = value;
      }
    }
    if (!_.isNil(item?._id)) {
      initObject[item._id] = newValue;
    }
  });
  return initObject;
};

export const setCusvalValueToForm = (cusField: any) => {
  let newCusField = cusField;
  if (newCusField) {
    newCusField = getFormInitialObject(cusField as OhsCusval[]);
  }
  return newCusField;
};

export const returnInvalidCusvals = (allValues: any[], cusvalsConfig: OhsCusval[]) => {
  const invalidCusvals = new Array<OhsCusval>();
  const keyList = (allValues || []).map((item) => item._id);

  for (let index = 0; index < keyList.length; index += 1) {
    const cusvalId = keyList[index];
    const targetCusval = _.find(cusvalsConfig, { _id: cusvalId });
    if (!targetCusval) {
      let invalidItem = _.find(allValues, { _id: cusvalId });

      if (
        invalidItem.type === OhsCusvalType.Date &&
        invalidItem.value &&
        invalidItem.value.indexOf('T') > 0
      ) {
        invalidItem = { ...invalidItem, value: invalidItem.value.split('T')[0] };
      }

      invalidCusvals.push(invalidItem);
    }
  }
  return invalidCusvals;
};

export function combineCusvalConfigAndValue(
  cusConfigList: OhsCusval[],
  cusValueList: OhsCusval[]
): OhsCusval[] {
  for (let index = 0; index < cusConfigList.length; index += 1) {
    const config = cusConfigList[index];
    const value = _.find(cusValueList, { _id: config._id });
    if (value) {
      if (config.type === OhsCusvalType.Date && value.value && value.value.indexOf('T') > 0) {
        config.value = value.value.split('T')[0];
      } else if (
        (config.type === OhsCusvalType.Radio || config.type === OhsCusvalType.Select) &&
        value.value
      ) {
        config.value = value.value[0];
      } else {
        config.value = value.value;
      }
    }
  }
  const unfoundList = new Array<OhsCusval>();
  for (let index = 0; index < cusValueList.length; index += 1) {
    const cusVale = cusValueList[index];
    const cusConfig = _.find(cusConfigList, { _id: cusVale._id });
    if (cusConfig == null) {
      cusVale.deleted = true;
      if (cusVale.type === OhsCusvalType.Checkbox) {
        cusVale.options = cusVale.value.map((item: string) => {
          return { name: item };
        });
      }
    }
  }

  return unfoundList.concat(cusConfigList);
}
function flattenNestedCusvalSelectArray(nestedArray: any) {
  return nestedArray
    .map((level: any) => {
      if (Array.isArray(level)) {
        return level.join(' > ');
      }
      return level;
    })
    .join(', ');
}
export const getCusvalDisplayValue = (cusval: OhsCusval, retainArray?: boolean) => {
  switch (cusval.type) {
    case OhsCusvalType.Color:
    case OhsCusvalType.Email:
    case OhsCusvalType.Number:
    case OhsCusvalType.Text:
    case OhsCusvalType.Textarea:
    case OhsCusvalType.Url:
    case OhsCusvalType.Telephone:
      return cusval.value;
    case OhsCusvalType.Time:
      return cusval.value?.hours || cusval.value?.hours === 0
        ? displayTime(cusval.value)
        : cusval.value;
    case OhsCusvalType.Checkbox:
    case OhsCusvalType.Select:
    case OhsCusvalType.MutipleSelect:
      return retainArray ? cusval.value : flattenNestedCusvalSelectArray(cusval.value);
    case OhsCusvalType.Radio:
      return cusval.value ? cusval.value.toString() : '';
    case OhsCusvalType.Currency:
      return cusval.value ? `${cusval.value.code} ${cusval.value.amount}` : '';
    case OhsCusvalType.Date:
      return cusval.value && cusval.value.indexOf('T') > -1
        ? `${cusval.value.split('T')[0]}`
        : cusval.value || '';
    default:
      return cusval.value;
  }
};

export const returnMatchingCusvalByConfig = (
  cusvalConfigs: OhsCusval[],
  allValues: any,
  invalidValues: OhsCusval[]
) => {
  const newCusvalObj: any = {};

  // add the invalid values
  invalidValues.forEach((item: OhsCusval) => {
    newCusvalObj[item._id] = item.value;
  });

  // autofill field values that are previously filled if it matches the current config
  cusvalConfigs.forEach((cusval: OhsCusval) => {
    const value = _.get(allValues, cusval._id) || '';
    if (value) {
      newCusvalObj[cusval._id] = value;
    }
  });

  return newCusvalObj;
};

export const createBulkCusvalDef = async (
  cusvalSaveList: OhsCusvalSaveType[],
  orgTier?: OhsTier
): Promise<OhsCusvalSaveType[]> => {
  if (!cusvalSaveList.length) return [];
  const payload = {
    orgTier,
    items: [] as any[],
  };
  cusvalSaveList.forEach((item) => payload.items.push({ cusvals: item.cusvals }));

  return OhsApiRequest<OhsCusvalDefinition[]>(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.DefinitionBulkCreate,
    payload
  ).then((defList) => {
    if (defList == null || defList.length !== cusvalSaveList.length) return [];
    for (let index = 0; index < defList.length; index += 1) {
      const def = defList[index];
      cusvalSaveList[index].cusvalDef = def;
    }

    return cusvalSaveList;
  });
};

export const manuallyFormatCusvals = (cusvals: OhsCusval[]) => {
  return cusvals?.map((item: any) => {
    if (item.type === 'date') {
      // Convert value to date format 'YYYY-MM-DD'
      const formattedDate = item.value ? moment(new Date(item.value)).format('YYYY-MM-DD') : '';
      return { ...item, value: formattedDate };
    }
    return item;
  });
};
