import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from 'app/store';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { OhsUserState } from 'user/userSlice';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsGlobalSearchFilter, OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import { initialGlobalSearchPayload } from 'search/store/OhsSearchSlice';
import globalModuleSearch from 'search/OhsSearchServices';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import handleRegisterAsyncData, {
  handleRegisterCountAsyncData,
} from 'global-components/register/OhsModuleRegisterUtils';
import { updateSearchInfo } from 'search/OhsSearchUtils';

import getAssetLists, { getAssetAllocatedList, getAssetWithAllocations } from './OhsAssetServices';
import {
  AllocatedListType,
  AssetListsRecordState,
  OhsAssetListsFilterPayload,
  OhsAssetsRootState,
  OhsAssetsViewPresets,
  OhsOrgTabFilterPayload,
  OhsWorkplaceTabFilterPayload,
} from './models/OhsAssetModels';
import OhsAssetRecord from './models/OhsAssetRecord';

const assetsSearch = async (
  search: OhsGlobalSearchPayload,
  count: boolean,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsAssetRecord[]> | null> => {
  const requiredSearchFilters =
    apiRequestName === OhsApiRequestName.WorkerGroupList
      ? {
          archived: search.filter?.archived,
          page: search.filter?.page,
          count: search.filter?.count,
          modules: search.filter?.modules,
        }
      : search.filter;
  const searchInfo = { ...search, filter: { ...requiredSearchFilters, count } };
  const globalSearchRes: any = await globalModuleSearch(searchInfo, apiRequestName);
  return globalSearchRes.result;
};

const initialState: AssetListsRecordState = {
  isLoading: false,
  allocatedList: null,
  assetLists: null,
  currentPage: 1,
  currentViewPreset: 'view_1',
};

export const fetchAssetAllocatedListAsync = createAsyncThunk<
  AllocatedListType[],
  { _ids: string[] },
  { state: RootState }
>('asset/fetchAssetAllocatedList', async (assetIDs, thunkAPI): Promise<any> => {
  try {
    const response = await getAssetAllocatedList(assetIDs._ids);
    const resVal = response;
    return resVal;
  } catch (err: any) {
    const error: AxiosError<any> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

const assetListsRPC = async (
  user: OhsUserState,
  setAssetListsFilters:
    | OhsOrgTabFilterPayload
    | OhsWorkplaceTabFilterPayload
    | OhsAssetListsFilterPayload,
  searchPayload: OhsGlobalSearchPayload
): Promise<any> => {
  const userTier = user.user?.tierNum;
  let searchRequestApi = OhsApiRequestName.List;
  if (setAssetListsFilters.viewPreset === 'view_1') {
    searchRequestApi = OhsApiRequestName.OrgList;
  } else if (setAssetListsFilters.viewPreset === 'view_2') {
    searchRequestApi = OhsApiRequestName.OrgWorkplaceList;
  }

  const searchInfo = updateSearchInfo(searchPayload, searchRequestApi, user);
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  if (
    userTier &&
    (userTier === 2 || userTier === 4) &&
    user.user?.configs.asset?.PERMISSIONS.view
  ) {
    const response = hasGlobalSearch
      ? await assetsSearch(searchInfo, false, searchRequestApi)
      : await getAssetLists(setAssetListsFilters);
    return response;
  }
  if (userTier && userTier === 3 && user.user?.configs.asset?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await assetsSearch(searchInfo, false, searchRequestApi)
      : await getAssetWithAllocations(setAssetListsFilters);
    return response;
  }
  if (userTier && userTier === 5 && user.user?.configs.asset?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await assetsSearch(searchInfo, false, OhsApiRequestName.WorkerGroupList)
      : await getAssetLists(setAssetListsFilters);
    return response;
  }
  return null;
};

const assetsFilterOptions = (
  filter: OhsFilterList,
  viewPreset: string,
  tierNum: number
): Partial<OhsAssetListsFilterPayload> => {
  if (tierNum === 3) {
    if (viewPreset === OhsAssetsViewPresets.View1AssetsList) {
      return {
        viewPreset: OhsAssetsViewPresets.View1AssetsList,
        categories: filter.assetModule.categories ?? [],
        subcategories: filter.assetModule.subcategories ?? [],
        riskRatings: filter.assetModule.riskRatings ?? [],
      };
    }
    if (viewPreset === OhsAssetsViewPresets.View2AssetsList) {
      return {
        viewPreset: OhsAssetsViewPresets.View2AssetsList,
        categories: filter.assetModule.categories ?? [],
        subcategories: filter.assetModule.subcategories ?? [],
        riskRatings: filter.assetModule.riskRatings ?? [],
        workplaces: filter.assetModule.workplaces ?? [],
        allocatedToWorkplace: filter.assetModule.allocatedToWorkplace,
      };
    }
    if (viewPreset === OhsAssetsViewPresets.View3AssetsList) {
      return {
        viewPreset: OhsAssetsViewPresets.View3AssetsList,
        categories: filter.assetModule.categories ?? [],
        subcategories: filter.assetModule.subcategories ?? [],
        riskRatings: filter.assetModule.riskRatings ?? [],
        archived: filter.assetModule.archived,
        allocatedToWorkplace: filter.assetModule.allocatedToWorkplace,
        allocatedToOrg: filter.assetModule.allocatedToOrg,
        notAllocated: filter.assetModule.notAllocated,
      };
    }
  } else if (tierNum === 4 || tierNum === 5) {
    return {
      viewPreset: OhsAssetsViewPresets.View4AssetsList,
      categories: filter.assetModule.categories ?? [],
      subcategories: filter.assetModule.subcategories ?? [],
      riskRatings: filter.assetModule.riskRatings ?? [],
      archived: filter.assetModule.archived,
      allocatedToWorkplace: filter.assetModule.allocatedToWorkplace,
    };
  }
  return {};
};

export const fetchAssetListsAsync = createAsyncThunk<ApiResListsType<OhsAssetRecord[]> | null>(
  'asset/fetchAssetList',
  async (_, thunkAPI) => {
    const {
      user,
      asset,
      globalfilter: { filterInfo },
      globalSearch,
    }: any = thunkAPI.getState() as OhsAssetsRootState;

    const { currentViewPreset, currentPage } = asset;

    const filters = assetsFilterOptions(filterInfo, currentViewPreset, user.user.tierNum);
    const setAssetListFilters: any = {
      ...filters,
      next: currentPage > 10 ? asset?.pagination.next ?? '' : undefined,
      // remove page if it is greater than 10
      ...(currentPage <= 10 && { page: currentPage }),
      sort: { ...JSON.parse(String(filterInfo.assetModule.sort)) },
      count: false,
    };

    try {
      const response = assetListsRPC(user, setAssetListFilters, globalSearch?.searchInfo);
      return await response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchAssetListsCountAsync = createAsyncThunk<ApiResListsType<OhsAssetRecord[]> | null>(
  'asset/fetchAssetListWithCount',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as OhsAssetsRootState;
    const { currentViewPreset } = state.asset;
    const assetListFilterWithCount: any = {
      ...assetsFilterOptions(
        state.globalfilter.filterInfo,
        currentViewPreset,
        state.user.user?.tierNum ?? 3
      ),
      page: 1,
      count: true,
    };
    let searchRequestApi = OhsApiRequestName.List;
    if (currentViewPreset === 'view_1') {
      searchRequestApi = OhsApiRequestName.OrgList;
    } else if (currentViewPreset === 'view_2') {
      searchRequestApi = OhsApiRequestName.OrgWorkplaceList;
    }

    const searchInfo = updateSearchInfo(
      state.globalSearch.searchInfo,
      searchRequestApi,
      state.user
    );
    const hasGlobalSearch = searchInfo?.searchKey !== '';

    const requiredApirequest =
      state.user.user?.tierNum === 5 ? OhsApiRequestName.WorkerGroupList : searchRequestApi;
    try {
      const response = hasGlobalSearch
        ? await assetsSearch(state.globalSearch.searchInfo, true, requiredApirequest)
        : await getAssetLists(assetListFilterWithCount);
      return response as ApiResListsType<OhsAssetRecord[]> | null;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setAssetIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<'view_1' | 'view_2' | 'view_3' | 'view_4'>) => {
      state.currentViewPreset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetListsAsync.pending, (state) => {
        state.isLoading = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })
      .addCase(fetchAssetListsAsync.fulfilled, (state, action) => {
        const assetListRes: any = action.payload;
        if (assetListRes) {
          const assetListData = handleRegisterAsyncData(assetListRes, 'assetListtotalPages');
          state.isLoading = false;
          state.assetLists = assetListData;
          state.currentPage = assetListData.pagination.page ?? 1;
        }
      })
      .addCase(fetchAssetListsAsync.rejected, (state) => {
        state.assetLists = null;
      })
      .addCase(fetchAssetAllocatedListAsync.pending, (state) => {
        state.allocatedList = [];
      })
      .addCase(fetchAssetAllocatedListAsync.fulfilled, (state, action) => {
        state.allocatedList = action.payload ?? [];
        state.isLoading = false;
      })
      .addCase(fetchAssetListsCountAsync.fulfilled, (state, action) => {
        const assetCountResult: any = action.payload;
        if (state.assetLists && assetCountResult) {
          const countAssetLists = handleRegisterCountAsyncData(
            assetCountResult,
            state.assetLists,
            'assetListtotalPages'
          );
          state.assetLists = countAssetLists;
        }
      });
  },
});

const assetState = (state: RootState) => state.asset;
export const getOhsAssetModuleState = createSelector([assetState], (asset) => asset);

export const { setCurrentRegisterPage, setAssetIsLoading, setViewPreset } = assetSlice.actions;
export const assetModuleReducer = assetSlice.reducer;

export const defaultTaskSearchFilter: OhsGlobalSearchFilter = {
  ...initialGlobalSearchPayload.filter,
  modules: [ModuleType.Asset],
};
export const initialAssetsSearch: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: false,
  highlight: false,
  filter: defaultTaskSearchFilter,
};
