import React from 'react';

import _ from 'lodash';
import { FieldValues } from 'react-hook-form';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import { OhsSelectOption } from 'user/OhsUserModels';

interface Props {
  id: string;
  ohsFormSettings: OhsFormSettings<FieldValues>;
  categoriesList: any[];
}

export default function OhsSubcategoriesMultiSelectFilter(props: Props) {
  const subCatLists = React.useMemo(() => {
    const slist = props.categoriesList?.map((a) => a.subcategory)?.flat();
    const modifiedSlist = slist?.map((subcategory) => {
      if (subcategory && typeof subcategory === 'object' && 'title' in subcategory) {
        // If subcategory is an object and has a 'title' property, return the title
        return subcategory.title;
      }
      return subcategory;
    });
    return _.sortBy(_.uniqBy([...new Set(modifiedSlist)], (item) => item));
  }, [props.categoriesList]);

  const subCategoryLists: OhsSelectOption[] =
    subCatLists?.map((subcat: string) => ({
      name: subcat,
      value: subcat,
    })) ?? [];

  return (
    <div>
      <OhsMultipleSelectField
        title="Subcategories"
        id={props.id}
        required={false}
        options={subCategoryLists}
        ohsFormSettings={props.ohsFormSettings}
      />
    </div>
  );
}
