import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { getOhsUser } from 'user/userSlice';
import { useAppSelector } from 'app/hooks';
import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsCorporateRiskModuleState } from 'corporate-risk/OhsCorporateRiskSlice';
import { OhsCRiskViewPresets } from 'corporate-risk/models/OhsCorporateRiskModels';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsCategoriesMultSelectFilter from '../filterfields/OhsCategoriesFilter';
import OhsResidualRiskMultSelectFilter from '../filterfields/OhsResidualRisk';
import OhsSubcategoriesMultiSelectFilter from '../filterfields/OhsSubcategoriesFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsCorporateRiskFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = useAppSelector(getOhsUser);
  const corporateRisk = useAppSelector(getOhsCorporateRiskModuleState);
  const userTierNum = user?.tierNum ?? 0;
  const getModuleCorporateRiskCategories = user.configs.corporateRisk?.CLASSIFICATION_LIST ?? [];
  const getModuleCorporateRiskResidualRisks = user.configs.corporateRisk?.RISK_RATING_LIST ?? [];
  const viewPreset = corporateRisk.currentViewPreset;
  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="corporateRiskModule.sort"
          required
          options={filter.corporateRiskModule?.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum <= 3 && viewPreset === OhsCRiskViewPresets.View2CRiskList && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`corporateRiskModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsCategoriesMultSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id={`corporateRiskModule.${viewPreset}.categories`}
          options={getModuleCorporateRiskCategories}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSubcategoriesMultiSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id={`corporateRiskModule.${viewPreset}.subcategories`}
          categoriesList={getModuleCorporateRiskCategories}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsResidualRiskMultSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id={`corporateRiskModule.${viewPreset}.residualRisks`}
          options={getModuleCorporateRiskResidualRisks}
        />
      </Grid>
      {userTierNum <= 3 && viewPreset === OhsCRiskViewPresets.View3CRiskList && (
        <>
          <Grid item md={12} xs={12} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid={`corporateRiskModule.${viewPreset}.allocatedToOrg`}
              label="Allocated to Organisation"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
          <Grid item md={12} xs={12} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid={`corporateRiskModule.${viewPreset}.notAllocated`}
              label="Not Allocated"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
        </>
      )}
      {viewPreset !== OhsCRiskViewPresets.View1CRiskList && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`corporateRiskModule.${viewPreset}.allocatedToWorkplace`}
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {((userTierNum <= 3 && viewPreset === OhsCRiskViewPresets.View2CRiskList) ||
        userTierNum === 4 ||
        userTierNum === 5) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`corporateRiskModule.${viewPreset}.workplaceOwned`}
            label="WorkPlaceOwned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsCorporateRiskFilters;
