import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsUser } from 'user/userSlice';
import { getOhsChemicalModuleState } from 'chemical/store/OhsChemicalSlice';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsChemicalLocationFilterCheckList from '../filterfields/OhsChemicalLocationFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsChemicalFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = useAppSelector(getOhsUser);
  const chemical = useAppSelector(getOhsChemicalModuleState);
  const userTierNum = user?.tierNum ?? 0;
  const locationOptions = user.configs.chemical?.LOCATION_LIST ?? [];
  const viewPreset = chemical.currentViewPreset;
  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="chemicalModule.sort"
          required
          options={filter.chemicalModule?.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum <= 3 && viewPreset === 'view2chemicalList' && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`chemicalModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {/* Temporarily Showing Loction Filter only for Org Tab at T3 */}
      {(userTierNum !== 3 || viewPreset === 'view1chemicalList') && !!locationOptions.length && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsChemicalLocationFilterCheckList
            id={`chemicalModule.${viewPreset}.locations`}
            ohsFormSettings={props.ohsFormSettings}
            options={locationOptions}
          />
        </Grid>
      )}

      {userTierNum <= 3 && viewPreset === 'view3chemicalList' && (
        <>
          <Grid item md={12} xs={12} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid={`chemicalModule.${viewPreset}.allocatedToOrg`}
              label="Allocated to Organisation"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
          <Grid item md={12} xs={12} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid={`chemicalModule.${viewPreset}.notAllocated`}
              label="Not Allocated"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
        </>
      )}
      {viewPreset !== 'view1chemicalList' && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`chemicalModule.${viewPreset}.allocatedToWorkplace`}
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {((userTierNum <= 3 && viewPreset === 'view2chemicalList') ||
        userTierNum === 4 ||
        userTierNum === 5) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`chemicalModule.${viewPreset}.workplaceOwned`}
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {/* Temporarily Showing Loction Filter only for Org Tab at T3 */}
      {(userTierNum !== 3 || viewPreset === 'view1chemicalList') && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`chemicalModule.${viewPreset}.locations_EMPTY`}
            label="Chemicals with No Locations"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsChemicalFilters;
