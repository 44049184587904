import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import OhsCusvalValue from 'global-components/form/cusvals/OhsCusvalValue';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import { OhsActionLink } from 'action/OhsActionModels';
import { AdminSwitchOptionMap } from 'user/configurations/admin2/OhsAdmin2Config';

import { OhsAttachment } from '../../global-components/form/fields/attachments/OhsAttachmentModels';

export interface OhsReviewPlansCustomFilters {
  archived?: boolean;
  workplaces?: string[];
}

export interface OhsReviewPlansFilterPayload extends OhsReviewPlansCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  page?: number;
  sort?: {
    order: number;
    key: string;
  };
  categories?: string[];
  subcategories?: string[];
  workplaceOwned?: boolean;
  count?: boolean;
  allocatedToOrg?: boolean;
  allocatedToWorkplace?: boolean;
  notAllocated?: boolean;
}

export interface OhsHolderLinks {
  type: ModuleType.Contractor | ModuleType.HR;
  _id: string;
  businessName: string;
  email?: string;
  name?: string;
  contactName?: string;
  contactEmail?: string;
}

class OhsReviewPlanRecord {
  _id?: string;

  tier?: OhsTier;

  type?: ModuleType;

  actionLinks?: OhsActionLink[];

  allocationActive?: boolean;

  allocationOf?: { _id: string; type: ModuleType.ReviewPlan };

  attachments?: OhsAttachment[];

  category?: string;

  categoryOther?: string;

  createdBy?: OhsModuleCreateBy;

  control?: { signatures?: string };

  dateCreated?: string;

  dateExpiry?: string;

  dateIssued?: string;

  holder?: string;

  holderLinks: OhsHolderLinks[];

  holderOther?: string;

  notes?: string;

  referenceId: string;

  subcategory?: string;

  subcategoryOther?: string;

  tzDateCreated?: string;

  cusvals?: OhsCusval[] | OhsCusvalValue | null;

  categoryCusvals?: OhsCusvalValue | null;

  subcategoryCusvals?: OhsCusval[] | OhsCusvalValue | null;

  reviewNotes?: string;

  subRows: any[];

  constructor(tier?: OhsTier) {
    this.tier = tier;
    this.type = ModuleType.ReviewPlan;
    this.allocationActive = false;
    this.holderLinks = [];
    this.subRows = [];
    this._id = '';
    this.attachments = [];
    this.category = '';
    this.categoryOther = '';
    this.holder = '';
    this.holderOther = '';
    this.notes = '';
    this.referenceId = '';
    this.subcategory = '';
    this.subcategoryOther = '';
    this.tzDateCreated = '';
    this.cusvals = null;
    this.subcategoryCusvals = null;
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.actionLinks = [];
    this.control = { signatures: AdminSwitchOptionMap.disabled };
  }
}
export default OhsReviewPlanRecord;
