import { ModuleType, OhsModuleCreateBy, TierType } from 'global-services/constants/OhsObject';

import OhsCorrespondenceExchangeRecipient from './OhsCorrespondenceExchangeRecipient';

export type Status = 'INCOMPLETE' | 'READY_TO_SEND' | 'SENT' | 'VIEWED' | 'CLOSED';
export const exchangeMappedStatus: Record<Status, string> = {
  INCOMPLETE: 'Incomplete',
  READY_TO_SEND: 'Ready to Send',
  SENT: 'Sent',
  VIEWED: 'Viewed',
  CLOSED: 'Closed',
};
interface CorrespondenceType {
  type: ModuleType.Correspondence;
  _id: string;
  title: string;
  tier?: {
    _id: string;
    type: TierType;
  };
}

interface Reply {
  required: boolean;
  rich: boolean;
  notes: string;
  multiple: boolean;
}
export interface Exchange {
  type: string;
  prose: CorrespondenceType;
  form: CorrespondenceType;
  date: string;
  time: {
    hours: number;
    minutes: number;
  };
  tz: string;
  reply: {
    comment: Reply;
    signatures: Reply;
    attachments: Reply;
  };
  closure: {
    type: string;
    buttonLabel: string;
    buttonColor: string;
    notes: string | null;
  };
}

export default class OhsCorrespondenceExchangeRecord {
  type: ModuleType;

  roll?: CorrespondenceType;

  createdBy: OhsModuleCreateBy;

  recipient?: OhsCorrespondenceExchangeRecipient;

  title: string;

  status: Status;

  dateCreated: string;

  tzDateCreated: string;

  subRows: any[];

  exchanges?: Exchange[];

  _id: string;

  isArchived: boolean;

  constructor() {
    this.type = ModuleType.Correspondence;
    this.createdBy = new OhsModuleCreateBy();
    this.title = '';
    this.dateCreated = '';
    this.tzDateCreated = '';
    this._id = '';
    this.status = 'VIEWED';
    this.isArchived = false;
    this.subRows = [];
  }
}
