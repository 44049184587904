export default class OhsCorrespondenceExchangeFilterModels {
  archived: boolean;

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  status: string[];

  constructor() {
    this.archived = false;
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
        { name: 'Date Created: Latest to Oldest', value: '{ "key": "dateCreated", "order": -1 }' },
        { name: 'Date Created: Oldest to Latest', value: '{ "key": "dateCreated", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "dateCreated", "order": -1 }';
    this.status = [];
  }
}
